import React from 'react';
import LoginMaster from '../../../../components/LoginMaster/LoginMaster';
import LoginForm from '../../../../components/Login/Login';

const CorporateLogin: React.FC = () => {
  return (<>
    <LoginMaster backPath='/Login'>
      <LoginForm />
    </LoginMaster>
  </>
  );
};
export default CorporateLogin;
