import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as styles from './LoginMaster.module.scss';
import logo from '../../assets/IMG/PrimeHRNewLogo .png';
import firstImg from '../../assets/IMG/Banner/1.webp';
import secondImg from '../../assets/IMG/Banner/2.webp';
import thirdImg from '../../assets/IMG/Banner/3.webp';
import forthImg from '../../assets/IMG/Banner/4.webp';
import fifthImg from '../../assets/IMG/Banner/5.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../Context/UserContext';
import { Button } from '../../Views/Portal/Courses/Components/Button/Button';

interface Props {
    backPath: string;
    children: any;
}

const LoginMaster: React.FC<Props> = ({ backPath, children }) => {
    const { User } = useContext(UserContext);
    const images: string[] = [firstImg, secondImg, thirdImg, forthImg, fifthImg];
    const [image, setImage] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            if ((image + 1) === images.length)
                setImage(0)
            else
                setImage(image + 1)
        }, 5000);
    }, [image]);

    useEffect(() => {
        let theme = localStorage.getItem("THEME");

        if (theme) {
            document.body.classList.remove("DarkMode");
            document.body.classList.remove("LightMode");
            document.body.classList.add(theme);
        }
    }, [])

    useEffect(() => {
        if (User.ID)
            navigate(User.isConsultant ? "/Consultant/Portal/Clients" : User.isEmployee ? User.OnboardingComplete ? `/Employee/Portal/` : `/Employee/Onboarding` : User.isAdmin ? "/Admin/" : "/Portal/");
    }, [User])

    return (<>
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <title>PrimeHR :: Login</title>
            <meta charSet='utf-8' />
        </Helmet>

        <div className={styles.Login}>
            <div className={styles.LoginBackground}>
                {
                    images.map((background, i) => {
                        return <img loading={"lazy"} className={`${i === image ? styles.Show : ""}`} src={`${background}`} />
                    })
                }
            </div>

            <div className={styles.LoginInner}>
                <div className={styles.Logo}>
                    <img loading={"lazy"} src={logo} />
                    <div className={styles.Back}>
                        <Button Color="Link" OnClick={() => navigate(backPath)}>
                            <i>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </i>
                            Back
                        </Button>
                    </div>
                </div>

                {children}
            </div>
        </div>
    </>
    );
};

export default LoginMaster;
