import React from 'react';
import Layout from '../../components/Layout/layout';
import CorporateLogin from '../../Views/Portal/Login/Corporate/Corporate';

const LoginPage: React.FC = () => {
  return (
    <Layout NoFooter={true} NoHeader={true}>
      <CorporateLogin />
    </Layout>
  );
};

export default LoginPage;
